import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container flex-column py-4">
      <h1>404: Not Found</h1>
      <p>The page you are looking for doesn&#39;t exist.</p>
      <Link to="/" className="round pink">
        Go back to Home
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
